import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contact from '../Components/Contact/Contact'
import HomePage from '../Components/HomePage/HomePage'
import Blog from '../Components/Blog/Blog'
import BlogPage from '../Components/BlogPage/BlogPage'
import SiteMap from '../Tools/SiteMap/SiteMap'
import NotFound from '../Tools/NotFound/NotFound'
import Faq from '../Components/Faq/Faq'
import FaqPage from '../Components/FaqPage/FaqPage'
import Thanks from '../Tools/Thanks/Thanks'

import ServicesPageServicesPage from '../Components/ServicesPage/ServicesPage'
import BookAnAppointment from '../Components/BookAnAppointment/BookAnAppointment'
import ServicesAll from '../Components/ServicesAll/ServicesAll'

import Careers from '../Components/Careers/Careers'
import HomePageCity from '../Components/HomePageCity/HomePageCity'
import ReviewsCust from '../Components/ReviewsCust/ReviewsCust'
import Locations from '../Components/Locations/Locations'


export default function Main({ navigateTo, Blogs, FaqList, WebSitePages }) {

    return (
        // <div>
        <Routes>
            <Route path="/" element={<HomePage navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            {/* City */}
            <Route path="/city/:city" element={<HomePageCity navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            
            {/* Services */}
            <Route path="/services" element={<ServicesAll navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/service/:service" element={<ServicesPageServicesPage navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            {/* Blog */}
            <Route path="/blog" element={<Blog Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/blog/:id" element={<BlogPage Blogs={Blogs} FaqList={FaqList} />} />
            {/* Faqs */}
            <Route path="/faq" element={<Faq FaqList={FaqList} Blogs={Blogs} />} />
            <Route path="/faq/:id" element={<FaqPage FaqList={FaqList} Blogs={Blogs} />} />
     
            {/*  */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/book" element={<BookAnAppointment />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/reviews" element={<ReviewsCust />} />
            <Route path="/thanks" element={<Thanks navigateTo={navigateTo} />} />
            <Route path="/careers" element={<Careers navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />


            {/* SiteMap */}
            {["/sitemap", "/sitemap", "/widgets"].map((path, index) =>
                <Route path={path} key={index} element={<SiteMap Blogs={Blogs} FaqList={FaqList} WebSitePages={WebSitePages} />} />
            )}
            {/* 404 */}
            <Route path="*" element={<NotFound />} />

            {/* Emails */}
            <Route path="/emails" element={<Faq FaqList={FaqList} Blogs={Blogs} />} />

        </Routes>
        // </div>
    )
}
